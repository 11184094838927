<template>
  <div>
    <content-box title="Board of Directors" class="col-lg-9 col-xl-7">
      <div v-if="loadingBoard" class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div v-else class="row justify-content-center">
        <contact-card
          v-for="member in board"
          :key="member.id"
          class="m-2"
          :contact="member"
        >
        </contact-card>
      </div>
    </content-box>
  </div>
</template>

<script>
import apiService from '@/services/apiService'

import contactCard from '@/components/contactCard'
import contentBox from '@/components/contentBox'

export default {
  name: 'board',
  components: {
    contactCard,
    contentBox,
  },
  data() {
    return {
      loadingBoard: false,
      board: [],
    }
  },
  created() {
    this.fetchBoD()
  },
  methods: {
    fetchBoD() {
      this.loadingBoard = true

      apiService
        .getBoD()
        .then((yay) => {
          this.loadingBoard = false
          this.board = yay
        })
        .catch(function () {
          this.loadingBoard = false
        })
    },
  },
}
</script>

<style scoped></style>

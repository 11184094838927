<template>
  <div
    class="media border border-primary bg-secondary rounded"
    style="
      min-width: 250px;
      max-width: 250px;
      min-height: 95px;
      max-height: 95px;
    "
  >
    <img
      class="mx-2 my-1 border border-primary rounded"
      style="
        min-width: 65px;
        max-width: 65px;
        min-height: 65px;
        max-height: 65px;
      "
      :src="myAvatar"
      alt="Profile Image"
    />

    <div class="media-body">
      <h5 class="ml-3 mb-1 black-text" style="min-height: 24px">
        {{ contact.first }} {{ contact.last }}
      </h5>
      <p class="ml-3 mb-1" style="min-height: 24px">{{ contact.title }}</p>
      <a
        v-show="contact.email"
        class="ml-3 btn btn-primary btn-sm"
        :href="'mailto:' + contact.email"
      >
        Email
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contactCard',
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    myAvatar() {
      let baseAvatarDir = '/img/avatars/'
      let avatar

      if (this.contact.avatar) {
        avatar = baseAvatarDir + this.contact.avatar
      } else if (this.contact.sex === 'M') {
        avatar = baseAvatarDir + 'default-male.jpg'
      } else {
        avatar = baseAvatarDir + 'default-female.jpg'
      }

      return avatar
    },
  },
}
</script>

<style scoped></style>
